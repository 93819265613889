import React, { useEffect, useRef } from 'react';
import './App.css';
import VideoJS from './VideoJS'


function App() {
  const playerRef = React.useRef(null);
  const videoJsOptions = {
    controls: true,
    fluid: true,
    autoplay: false,
    responsive: true,
    poster: "https://d1qa26hdm75bfz.cloudfront.net/e84628bf-ed94-4cee-a654-cf536ab1ac55/thumbnails/gibi-s2-b5-mandosi_thumb.0000017.jpg",
    sources: [{
      src: "https://d1qa26hdm75bfz.cloudfront.net/out/v1/919720bef40e42d5ab7ae5e5954bef5f/77440854f90545afb1331a9c841e2e3b/4c26bf5e741f4558b85d792f32918491/index.m3u8",
      type: 'application/x-mpegURL'
    }],
  };
  const mainContentRef = useRef<HTMLDivElement>(null);
  const smallTextRef = useRef<HTMLSpanElement>(null);
  const largeTextRef = useRef<HTMLSpanElement>(null);

  const handlePlayerReady = (player:any) => {
    playerRef.current = player;
  };

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (!mainContentRef.current) {
        return;
      }
      const x = (e.pageX - ((mainContentRef.current?.offsetLeft as number | undefined) || 0) as number);
      const y = (e.pageY - ((mainContentRef.current?.offsetTop as number | undefined) || 0) as number);

      smallTextRef.current?.style.setProperty('transform', `translateX(${x / 50}px) translateY(${y / 100}px)`);
      largeTextRef.current?.style.setProperty('transform', `translateX(-${x / 50}px) translateY(-${y / 100}px)`);
    };

    mainContentRef.current?.addEventListener('mousemove', handleMouseMove);
    const currentMainContentRef = mainContentRef.current;

    return () => {
      // Clean up the event listener when the component unmounts
      currentMainContentRef?.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="main-content" ref={mainContentRef}>
      <header>
        <div className="logo">
          <span className="logo-text logo-text--back" ref={largeTextRef}>GiBi</span>
          <span className="logo-text logo-text--front" ref={smallTextRef}>gibi</span>
        </div>
      </header>
      <div className="main-video">
        <VideoJS options={videoJsOptions} onReady={handlePlayerReady} />
        </div>
    </div>
  )
}

export default App;
